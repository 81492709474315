<template>
<van-nav-bar
  title="选择收货人"
  left-text=""
  left-arrow
  @click-left="onGoBack"
  />
  <van-search
  v-model="value"
  shape="round"
  placeholder="搜索收货人"
  @update:model-value="getoperuserinfo"
  >
  </van-search>
  <div v-if="is_data">
  <van-cell v-for="item in userlist.data" :key="item"  is-link @click="getuserinfo(item)">
    <span style="margin-left:20px" @click="aa">{{item.user_name}}({{item.user_phone_num}})</span>
  </van-cell>
  </div>
</template>

<script>
import { ref,reactive,getCurrentInstance,} from 'vue';
import { useUserStore } from '@/store/user.ts'
import { useRouter } from 'vue-router'
import { Toast } from 'vant';

const userStore=useUserStore()
export default {
  setup() {
    const is_data = ref(false);
    let userlist= reactive({
      data: [{}]
    });

    const value = ref('');
    let { proxy } = getCurrentInstance();
    const getoperuserinfo=()=>{
      let params = {data: {}}
      params={'queryName':value.value}
      //proxy.$axios.post(process.env.VUE_APP_API_PORT_BASE_URL+'/user/getUser',params)
      let tokens={'Authorization':userStore.userInfo.token}
      proxy.$axios.post(process.env.VUE_APP_API_PORT_BASE_URL+'/user/getAllUserPartner',params,{headers:tokens})
      .then(res=>{
        userlist.data.length=0;
        for (var i=0;i<res.data.data.length;i++){
          console.log(res.data.data[i])
          userlist.data.push(res.data.data[i])
        }
        is_data.value=true
      })
    };
    
    const router = useRouter()
    const getuserinfo=(item)=>{
      console.log(item.id)
      router.push('/exwarehouse?user_id='+item.id+'&user_name='+item.user_name+'&user_phone_num='+item.user_phone_num);
    };

    const onSearch = (val) => Toast(val);
    const onClickButton = () => Toast(value.value);
    
    return {
      is_data,
      value,
      userlist,
      onSearch,
      onClickButton,
      getoperuserinfo,
      getuserinfo
    };
  },
};


</script>